<template>
    <div class="generate-report-card">
        <!-- <v-container> -->
            <!-- <v-row class="mx-2 my-2"> -->
                <!-- <v-col cols="6" md="9"> -->
                <div class="mx-4 my-2">
                    <h3 v-if="report_card_group && report_card_group.room">{{ report_card_group.title  }} - {{  this.report_card_group.room.sections }}</h3>
                    <v-list-item-subtitle>{{ moment(report_card_group.date).format("DD MMM YYYY") }} </v-list-item-subtitle>
                </div>

                <!-- </v-col> -->
                <!-- <v-col cols="6" md="3" class="text-right">
                    <v-btn color="primary" outlined>
                        <v-icon class="mx-2">mdi-import</v-icon>
                        Import
                    </v-btn> -->
                <!-- </v-col> -->

            <!-- </v-row> -->
            <v-card class="mx-2 my-2">
                <v-data-table
                    :headers="headers"
                    :items="report_card_group.report_cards"
                    disable-pagination
                    hide-default-footer
                    :class="(!report_card_group.is_draft && !is_adding && !is_editing) ? 'row-pointer' : null"
                    @click:row="(item) => (!report_card_group.is_draft && !is_adding && !is_editing) ? $router.push({ name: 'ViewReportCard', params: { report_id : item.id } }) : null"
                    v-if="report_card_group && report_card_group.parameter_groups"
                >
                    <template v-slot:header v-if="$vuetify.breakpoint.mdAndUp">
                        <thead class="v-data-table-header">
                            <tr>
                                <td class="primary--text">
                                    <strong>
                                        Group Name
                                    </strong>
                                </td>
                                <td class="blue--text text-center">
                                    <strong>
                                        Assignment
                                    </strong>
                                </td>
                                <td class="text-center primary--text" v-for="group in report_card_group.parameter_groups" :key="group.id" :colspan="group.group_parameters.length">
                                    <strong>{{ group.title }}</strong>
                                </td>
                            </tr>
                        </thead>
                    </template>
                    <template 
                        v-for="parameter in parameters"
                        v-slot:[`item.parameter-${parameter.id}`]="{ item }"
                    >
                        <div :key="parameter.id">
                            <v-rating
                                v-if="item.report_card_grades.find(grade => grade.parameter == parameter.id)"
                                background-color="primary lighten-3"
                                color="primary"
                                :readonly="!(is_adding || is_editing)"
                                v-model.number="item.report_card_grades.find(grade => grade.parameter == parameter.id).star_count"
                                @input="autoSave"
                            ></v-rating>
                            <div v-else>
                                <span>-</span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.full_name="{ item }">
                        <router-link v-if="!report_card_group.is_draft && !is_adding && !is_editing" :to="{ name: 'ViewReportCard', params: { report_id : item.id } }">{{ item.student.full_name }}</router-link>
                        <span v-else>{{ item.student.full_name }}</span>
                    </template>
                    <template v-slot:item.assignment_star_count="{ item }">
                        <v-rating
                            background-color="blue lighten-3"
                            color="blue"
                            readonly
                            v-model.number="item.assignment_star_count"
                            @input="autoSave"
                        ></v-rating>
                    </template>
                </v-data-table>
            </v-card>
            <div class="text-center my-4">
                <v-btn class="mx-2" color="red" outlined @click="() => {
                        if(this.is_editing){
                            is_editing=false;
                            getReportCardGroupDetails();
                        }
                        else{
                            $router.replace({ name: 'ReportCardDashboard'});
                        }
                    }"
                    v-if="is_editing || is_adding">Cancel</v-btn>
                <v-btn class="mx-2" color="primary" outlined @click="submitReportCardGroupDetails" v-if="is_adding || is_editing">Save</v-btn>
                <v-btn class="mx-2" color="primary" @click="showPublishDialog = true" v-if="report_card_group.is_draft">Publish</v-btn>

            </div>

            <intract-create-edit-sheet
                persistent
                key="report-card-group-edit-sheet"
                title="Edit Details"
                description="You can only edit the title of already generated report cards. To edit grades select the 'Edit Grades' option."
                :visible="editDetailsSheet.visible"
                @close="
                    editDetailsSheet.visible = false;
                    editDetailsSheet.title = report_card_group.title;
                "
                :fields="editDetailsSheet.formFields"
                submit-button-text="Save"
                :endpoint="endpoints.reportCardGroupViewSet"
                partial-update
                :edit-id="report_card_group.id"
                :data-object="editDetailsSheet.group_details"
                @objectCreated="(obj) => report_card_group = obj"
                @updateObject="(obj) => (editDetailsSheet.obj = obj)"
            >
            </intract-create-edit-sheet>
            
        <!-- </v-container> -->
        <!-- auto save -->
        <v-dialog
            :value="autoSaveDialog"
            persistent
            width="300"
        >
            <v-card
                color="red"
                dark
            >
                <v-card-text>
                <strong class="my-2">Auto Saving Reports Cards..</strong>
                    <v-progress-linear
                        indeterminate 
                        color="white"
                        v-if="autoSaveDialog"
                        class="my-2"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- delete dialog -->
        <confirmation-dialog
            :visible="showDeleteDialog"
            title="Are you sure you want to delete all the report cards?"
            description="This action cannot be undone."
            @successCallback="deleteReportCardGroup"
            @failureCallback="showDeleteDialog = false"
            delete-dialog
        />
        <!-- publish dialog -->
        <confirmation-dialog
            :visible="showPublishDialog"
            title="Are you sure you want to publish all the report cards?"
            description="A notification will be sent when published. Published report cards are visibile to students. This action cannot be undone, however report cards can be edited. To make any other changes like classroom or grading parameters, you will have to delete all the report cards of this group."
            @successCallback="publishReportCard"
            @failureCallback="showPublishDialog = false"
        />
    </div>
</template>

<script>
import Mixins from "@utils/mixins";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import moment from 'moment';
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";

export default{
    name: 'GenerateReportCard',
    mixins: [Mixins.essentials, Mixins.handleAppBarOptions ],
    components:{
        IntractCreateEditSheet,
        ConfirmationDialog,
    },
    data(){
        return {
            students: [],
            autoSaveCounter: 0,
            autoSaveDialog: false,
            showDeleteDialog: false,
            showPublishDialog: false,
            // room: null,
            // parameter_groups: [],
            is_adding: false,
            is_editing: false,
            parameters: [],
            report_card_group: {
                title: null,
                room: null,
                parameter_groups: [],
                report_cards: [],
            },
            editDetailsSheet:{
                visible: false,
                group_details: {
                    title: null,
                },
                formFields: {
                    title: {
                        fieldType: CreateEditFieldTypes.TEXTFIELD,
                        label: "Title",
                        required: true,
                        md: 12,
                        max: 255,
                    }
                },
                successMessage: "Details Saved"
            },
            moment: moment,
        }
    },
    computed:{
        appBarOptions() {
            return {
                actions: [
                    ...this.is_adding? [] : [{
                        id: 1,
                        title: "Edit Details",
                        icon: "mdi-pencil",
                        onClick: () => {
                            this.editDetailsSheet.group_details.title = this.report_card_group.title;
                            this.editDetailsSheet.visible = true;
                        },
                    }],
                    ...this.is_editing || this.is_adding ? [] : [{
                            id: 2,
                            title: "Edit Grades",
                            icon: "mdi-playlist-edit",
                            onClick: () => {
                                this.showSnackbar({
                                    title: 'You can now edit the grades',
                                    type: 'info'
                                });
                                this.is_editing = true;
                        },

                    }],
                    ...this.is_adding ? [] : [{
                        id: 3,
                        title: "Delete",
                        icon: "mdi-delete",
                        onClick: () => (this.showDeleteDialog = true),
                    }],
                ],
            };
        },
        headers(){
            let headers = [
                {
                    text: 'Student',
                    align: 'left',
                    sortable: false,
                    value: 'full_name',
                    group_name: null,
                    width: 200
                },
                {  
                    text: 'Average',
                    align: 'center',
                    sortable: false,
                    value: 'assignment_star_count',
                    group_name: null,
                    width: 200
                }
            ];
            this.parameters.forEach(parameter => headers.push({
                text: parameter.title,
                align: 'center',
                sortable: false,
                value: `parameter-${parameter.id}`,
                group_name: parameter.group_name,
            }));
            return headers
        }
    },
    methods:{
        async submitReportCardGroupDetails(){
            var post_body = {
                title: this.report_card_group.title,
                room: this.report_card_group.room.id,
                date: this.is_adding ? moment().format('YYYY-MM-DD'): this.report_card_group.date,
                parameter_groups: this.report_card_group.parameter_groups.map(group => group.id),
                report_cards : this.report_card_group.report_cards,
                creator: this.is_adding ? this.currentUser.id: this.report_card_group.creator,
                is_draft: this.is_adding ? true : this.report_card_group.is_draft
            }
            var url = this.endpoints.reportCardGroupViewSet;
            if(this.is_editing){
                url += `${ this.$route.params.group_id }/`;
            }
            var response = await this.api.call(this.essentials, url, this.is_editing ? this.api.Methods.PUT : this.api.Methods.POST , post_body)
            if(this.is_editing){
                this.report_card_group = response;
                if(!this.autoSaveDialog){
                    this.is_editing = false;
                }
                this.showSnackbar({
                    title: 'Report cards saved',
                    type: 'success'
                });
                this.autoSaveCounter = 0;

            }
            if(this.is_adding){
                this.is_adding = false;
                this.showSnackbar({
                    title: 'Report cards saved as draft',
                    type: 'success'
                });
                this.autoSaveCounter = 0;
                this.$router.replace({ name: 'ViewReportCards', params: { group_id: response.id }, query: {auto_save: true} })
            }
        },

        async deleteReportCardGroup(){
            this.showDeleteDialog = false;
            var url = this.endpoints.reportCardGroupViewSet + `${this.$route.params.group_id}/`;
            var response = await this.api.call(
                this.essentials,
                url,
                this.api.Methods.DELETE
            );
            if(response){
                this.showSnackbar({
                    title: "Report cards deleted successfully",
                    type: "success",
                });
                this.$router.back();
            }
        },

        async getRoomDetails(){
            var url = this.endpoints.classroomViewSet + `${this.$route.query.room}/`;
            this.report_card_group.room = await this.api.call(this.essentials,url);
        },

        async autoSave(){
            this.autoSaveCounter++;
            if(this.autoSaveCounter>=15){
                this.autoSaveDialog = true;
                await this.submitReportCardGroupDetails();
                this.autoSaveDialog = false;
            }
        },

        async getParameters(){
            var url = this.Helper.addUrlParams(this.endpoints.reportCardParameterGroupViewSet,['paginate=false',`id__in=${this.$route.query.parameter_groups.toString()}`])
            this.report_card_group.parameter_groups = await this.api.call(this.essentials,url);
            this.initAllParamters();
        },

        async publishReportCard(){
            var url = this.endpoints.reportCardGroupViewSet + `${ this.$route.params.group_id }/`;
            var patch_body = {
                is_draft: false
            }
            this.report_card_group = await this.api.call(this.essentials,url,this.api.Methods.PATCH,patch_body)
            this.showSnackbar({
                title: 'Report Cards Published',
                type: 'success'
            });
            this.showPublishDialog = false;
            this.is_editing = false;
            
        },

        initAllParamters(){
            this.parameters = [];
            this.report_card_group.parameter_groups.forEach(group => {
                let parameters_with_group_name = group.group_parameters.map((p) => {
                    var parameter = this.Helper.clone(p);
                    parameter.group_name = group.title;
                    return parameter;
                })
                this.parameters.push(...parameters_with_group_name)}
            );
        },


        async getReportCardGroupDetails(){
            var url = this.endpoints.reportCardGroupViewSet + `${this.$route.params.group_id}/`;
            this.report_card_group = await this.api.call(this.essentials,url)
        },


        async getClassroomStudents(){
            var date = this.is_adding ? moment().format(this.Helper.dateURLFormat): this.report_card_group.date
            var url = this.Helper.addUrlParams(this.endpoints.reportCardGroupStudentViewSet,[
                `room=${this.$route.query.room}`,
                `date=${date}`,
                "registration_approved=true",
            ])
            this.students = await this.api.call(this.essentials,url)
            this.report_card_group.report_cards = this.students.map(student => {
                return{
                    assignment_star_count: student.assignment_average,
                    student : student,
                    report_card_grades: this.parameters.map(p => {
                        // var grade_obj = this.Helper.clone(p)
                        var grade_obj = {
                            parameter: p.id,
                            star_count : 0,
                        }
                        return grade_obj
                    })
                }
            })
        },
        
        async initReportCardGroup(){
            this.report_card_group.title = this.$route.query.title;
            await this.getRoomDetails();
            await this.getParameters();
            this.is_adding = true;
            await this.getClassroomStudents();
        },
        // called from watcher
        // required since async in await and 'this' cannot be found
        async autoSaveNewReportCard(){
            await this.getReportCardGroupDetails();
            await this.initAllParamters();
            // if saved due to auto-save
            if(this.$route.query.auto_save){
                this.is_editing = true;
            }
        }

    },
    async created(){
        // check all query params exist or redirect
        if(this.$route.params.group_id){
            // Viewing
            await this.getReportCardGroupDetails();
            await this.initAllParamters();
            
        }
        else if(!['title','room','parameter_groups'].every(param => (Object.keys(this.$route.query)).includes(param))){
            // Check if available for generating
            this.$router.replace({ name: 'ReportCardDashboard' })
        }
        else{
            // Generating 
            this.initReportCardGroup();
        }
        // studentViewSet: SERVER_URL + 'base/students/',
    },
    watch: {
        '$route.params.group_id'(){
            this.autoSaveNewReportCard();
        }
    },
}
</script>

<style scoped>
table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; }
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>